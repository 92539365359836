import { render, staticRenderFns } from "./Favicon.vue?vue&type=template&id=fc71b004&scoped=true"
import script from "./Favicon.vue?vue&type=script&lang=js"
export * from "./Favicon.vue?vue&type=script&lang=js"
import style0 from "./Favicon.vue?vue&type=style&index=0&id=fc71b004&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc71b004",
  null
  
)

export default component.exports