<template>
  <b-form-group
    v-on-clickaway="hideDropdown"
    :label-for="id"
    :invalid-feedback="error"
    :state="error ? false : null"
    class="search-form-group"
  >
    <b-input
      type="text"
      :id="id"
      :placeholder="placeholder"
      :value="value"
      :size="size"
      :state="error ? false : null"
      :disabled="disabled"
      @focus="showDropdown = true"
      @input="handleInput"
    />

    <template v-slot:label v-if="label">
      {{ label }}
      <span v-b-tooltip.hover v-if="tooltip && !hideTooltip" :title="tooltip">
        <i class="info-form far fa-info-circle" />
      </span>
    </template>

    <template v-if="descriptionSlotFilled">
      <small class="form-text text-muted">
        <div class="input-description">
          <slot name="description">
            {{ description }}
          </slot>
        </div>
      </small>
    </template>

    <div class="input-search-results" :class="{ 'd-none': !showDropdown }">
      <p
        class="input-search-result"
        v-for="(result, index) of filteredResults"
        :key="index"
        @click="handleChoice(result)"
      >
        {{ result[textField] }}
      </p>
    </div>

    <span class="input-icon-append">
      <i class="fas fa-magnifying-glass" />
    </span>
  </b-form-group>
</template>

<script>
  import { mixin as clickaway } from "vue-clickaway";

  export default {
    mixins: [clickaway],

    props: {
      /**
       * The unique ID used for linking the labels to the input field
       */
      id: {
        type: String,
        default: null,
      },

      /**
       * The label to be shown
       */
      label: {
        type: String,
        default: null,
      },

      /**
       * The error to be shown when validation fails
       */
      error: {
        default: null,
      },

      /**
       * The placeholder to be shown
       */
      placeholder: {
        type: String,
        default: null,
      },

      textField: {
        type: String,
        default: null,
      },

      /**
       * The description to be shown
       */
      description: {
        type: String,
        default: null,
      },

      /**
       * The tooltip to be shown
       */
      tooltip: {
        type: String,
        default: null,
      },

      /**
       * Disabled state
       */
      disabled: {
        type: Boolean,
        default: false,
      },

      size: {
        type: String,
        default: null,
      },

      results: {
        type: Array,
        default: () => {
          return [];
        },
      },

      /**
       * The value to track
       */
      value: {
        default: null,
      },
    },

    data() {
      return {
        showDropdown: false,

        filteredResults: [],
      };
    },

    computed: {
      descriptionSlotFilled() {
        return !!this.$slots.description || this.description;
      },
    },

    methods: {
      handleInput(value) {
        this.filteredResults = this.results.filter((item) => {
          return item.title.toLowerCase().includes(value.toLowerCase());
        });

        // Set pseudo choice when we are adding a custom item which is not in the database
        const choice = {
          'title': value
        };

        this.handleChoice(choice);
      },

      hideDropdown() {
        this.showDropdown = false;
      },

      handleChoice(choice) {
        this.showDropdown = false;
        this.value = choice.formatted_name ? choice.formatted_name : choice.title;

        this.$emit("choice", choice);
        this.$emit("input", this.value);
      },
    },

    mounted() {
      this.filteredResults = this.results;
    },
  };
</script>
