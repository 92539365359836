<template>
  <b-container fluid>
    <b-button variant="transparent" @click="resetLayout()" class="text-white">
      <i class="fas fa-rotate mr-2" /> Layout resetten
    </b-button>

    <grid-layout
      :layout.sync="layout"
      :row-height="10"
      :is-draggable="true"
      :is-resizable="true"
      :vertical-compact="true"
      :responsive="false"
      :margin="[20, 20]"
      @layout-updated="saveLayout"
    >
      <grid-item
        v-for="item in layout"
        :minW="3"
        :minH="6"
        :maxH="14"
        :maxW="6"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.i"
      >
        <div class="controls">
          <span ref="handle" class="vue-resizable-handle">
            <i class="far fa-arrow-up-right-and-arrow-down-left-from-center" />
          </span>
        </div>

        <DownSites v-if="item.i === 'down-sites'" />
        <PhoneCalls v-if="item.i === 'phone-calls'" />
        <NewVulnerabilities v-if="item.i === 'new-vulnerabilities'" />
        <SitesWithVulnerabilities
          v-if="item.i === 'sites-with-vulnerabilities'"
        />
        <NewSites v-if="item.i === 'new-sites'" />
        <LastSyncedSites v-if="item.i === 'last-synced-sites'" />
      </grid-item>
    </grid-layout>
  </b-container>
</template>

<script>
  import { mapGetters } from "vuex";
  import { GridLayout, GridItem } from "vue-grid-layout";
  import DownSites from "./Grid/DownSites";
  import NewSites from "./Grid/NewSites";
  import NewVulnerabilities from "./Grid/NewVulnerabilities";
  import PhoneCalls from "./Grid/PhoneCalls";
  import SitesWithVulnerabilities from "./Grid/SitesWithVulnerabilities";
  import LastSyncedSites from "./Grid/LastSyncedSites";

  export default {
    components: {
      GridLayout,
      GridItem,
      DownSites,
      NewSites,
      NewVulnerabilities,
      PhoneCalls,
      SitesWithVulnerabilities,
      LastSyncedSites,
    },

    data() {
      return {
        layout: [],
        default_layout: [
          { x: 9, y: 0, w: 3, h: 14, i: "down-sites" },
          { x: 6, y: 0, w: 3, h: 14, i: "new-sites" },
          { x: 0, y: 0, w: 6, h: 14, i: "new-vulnerabilities" },
          { x: 3, y: 21, w: 3, h: 7, i: "sites-with-vulnerabilities" },
          { x: 3, y: 14, w: 3, h: 7, i: "phone-calls" },
          { x: 0, y: 14, w: 3, h: 14, i: "last-synced-sites" },
        ],
      };
    },

    computed: {
      ...mapGetters({
        auth: "auth/user",
      }),
    },

    methods: {
      saveLayout(event) {
        localStorage.setItem("layout", JSON.stringify(event));
      },

      applyLayout() {
        this.layout = localStorage.getItem("layout")
          ? JSON.parse(localStorage.getItem("layout"))
          : JSON.parse(JSON.stringify(this.default_layout));
      },

      resetLayout() {
        localStorage.removeItem("layout");

        this.layout = JSON.parse(JSON.stringify(this.default_layout));
      },
    },

    mounted() {
      this.applyLayout();
    },
  };
</script>