<template>
  <b-form-group
    v-on-clickaway="hideDropdown"
    :label-for="id"
    :invalid-feedback="error"
    :state="error ? false : null"
    class="search-form-group"
  >
    <b-input
      type="text"
      :id="id"
      :placeholder="placeholder"
      :value="value ? value : data"
      :size="size"
      :state="error ? false : null"
      :disabled="disabled"
      @focus="showDropdown = true"
      @input="debounceInput"
    />

    <template v-slot:label v-if="label">
      {{ label }}
      <span v-b-tooltip.hover v-if="tooltip && !hideTooltip" :title="tooltip">
        <i class="info-form far fa-info-circle" />
      </span>
    </template>

    <template v-if="descriptionSlotFilled">
      <small class="form-text text-muted">
        <div class="input-description">
          <slot name="description">
            {{ description }}
          </slot>
        </div>
      </small>
    </template>

    <div class="input-search-results" :class="{ 'd-none': !showDropdown }">
      <template v-if="total">
        <p
          class="input-search-result"
          v-for="(result, index) of results"
          :key="index"
          @click="handleChoice(result)"
        >
          {{ result.formatted_name }} {{ result.title }}
        </p>
      </template>
      <template v-else>
        <p class="empty">Geen resultaten gevonden</p>
      </template>
    </div>

    <span class="input-icon-append">
      <i class="fas fa-magnifying-glass" />
    </span>
  </b-form-group>
</template>

<script>
  import debounce from "lodash/debounce";
  import { mixin as clickaway } from "vue-clickaway";

  export default {
    mixins: [clickaway],

    props: {
      /**
       * The unique ID used for linking the labels to the input field
       */
      id: {
        type: String,
        default: null,
      },

      /**
       * The label to be shown
       */
      label: {
        type: String,
        default: null,
      },

      /**
       * The error to be shown when validation fails
       */
      error: {
        default: null,
      },

      /**
       * The placeholder to be shown
       */
      placeholder: {
        type: String,
        default: null,
      },

      /**
       * The description to be shown
       */
      description: {
        type: String,
        default: null,
      },

      data: {
        type: String,
        default: "",
      },

      /**
       * The tooltip to be shown
       */
      tooltip: {
        type: String,
        default: null,
      },

      /**
       * Disabled state
       */
      disabled: {
        type: Boolean,
        default: false,
      },

      /**
       * Disabled state
       */
      hideTooltip: {
        type: Boolean,
        default: false,
      },

      /**
       * Disabled state
       */
      size: {
        type: String,
        default: null,
      },

      results: {
        type: Array,
        default: () => {
          return [];
        },
      },

      total: {
        type: Number,
        default: () => {
          return 0;
        },
      },
    },

    data() {
      return {
        value: null,
        showDropdown: false,
      };
    },

    computed: {
      descriptionSlotFilled() {
        return !!this.$slots.description || this.description;
      },
    },

    methods: {
      debounceInput: debounce(function (e) {
        this.$emit("input", e);
      }, 500),

      hideDropdown() {
        this.showDropdown = false;
      },

      handleChoice(choice) {
        this.showDropdown = false;
        this.value = choice.formatted_name ? choice.formatted_name : choice.title;

        this.$emit("choice", choice);
        this.$emit("input", this.value);
      },
    },
  };
</script>
