import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/views/Auth/Login';

import Dashboard from '@/views/Dashboard/Dashboard';
import PageNotFound from '@/views/PageNotFound';

import SitesIndex from '@/views/Sites/Index';
import SitesShow from '@/views/Sites/Show';

import SoftwareIndex from '@/views/Software/Index';
import SoftwareShow from '@/views/Software/Show';
import SoftwareShowVulnerability from '@/views/Software/ShowVulnerability';

import UsersIndex from '@/views/Users/Index';
import UsersShow from '@/views/Users/Show';
import UsersCreate from '@/views/Users/Create';

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter)

const routes = [
    /**
     * Auth routes
     */
    {
        path: '/',
        name: 'auth.login',
        component: Login,
        meta: {}
    },

    /**
     * Dashboard route
     */
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            exact: true,
            title: 'Home',
            icon: 'home',
            inNavigation: true,
            navigationOrder: 1,
        }
    },

    /**
     * Sites routes
     */
    {
        path: '/sites',
        name: 'sites.index',
        component: SitesIndex,
        meta: {
            title: 'Sites',
            icon: 'browser',
            inNavigation: true,
            navigationOrder: 2,
        }
    },
    {
        path: '/sites/:id/:tab?',
        name: 'sites.show',
        component: SitesShow,
        meta: {}
    },

    /**
     * Users routes
     */
    {
        path: '/gebruikers',
        name: 'users.index',
        component: UsersIndex,
        meta: {
            title: 'Gebruikers',
            icon: 'users',
            inNavigation: true,
            navigationOrder: 4,
        }
    },
    {
        path: '/gebruikers/aanmaken',
        name: 'users.create',
        component: UsersCreate,
        meta: {
            title: 'Gebruiker aanmaken',
            admin: true,
        }
    },
    {
        path: '/gebruikers/:id',
        name: 'users.show',
        component: UsersShow,
        meta: {
            title: 'Gebruiker bewerken',
            admin: true,
        }
    },
    {
        path: '/mijn-profiel',
        name: 'users.my-account',
        component: UsersShow,
        meta: {
            title: 'Mijn profiel',
        }
    },

    /**
     * Log routes
     */
    {
        path: '/logboek',
        name: 'log.index',
        component: Dashboard,
        meta: {
            title: 'Logboek',
            icon: 'heart-pulse',
            inNavigation: true,
            navigationOrder: 5,
        }
    },

    /**
    * Software routes
    */
    {
        path: '/software',
        name: 'software.index',
        component: SoftwareIndex,
        meta: {
            title: 'Software',
            icon: 'cube',
            inNavigation: true,
            navigationOrder: 3,
        }
    },
    {
        path: '/software/:id',
        name: 'software.show',
        component: SoftwareShow,
    },
    {
        path: '/software/:id/vulnerability/:vulnerabilityId',
        name: 'software.show.vulnerability',
        component: SoftwareShowVulnerability,
    },

    /**
     * Catch all (404) Route
     */
    {
        path: "*",
        name: 'page-not-found',
        component: PageNotFound
    }
]

const router = new VueRouter({
    base: '/',
    mode: 'history',
    routes
})

export default router