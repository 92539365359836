<template>
  <b-container fluid>
    <h1>{{ $route.meta.title }}</h1>

    <b-row>
      <b-col md="10">
        <b-card class="filters-sidebar">
          <b-form-group
            label="Zoeken"
            description="Druk op `Enter` om te zoeken"
          >
            <b-input
              v-model="filters.terms"
              placeholder="Plugin of thema"
              @keyup.enter="filter()"
            />
          </b-form-group>

          <b-form-group label="Type" class="mb-0">
            <b-form-checkbox-group
              v-model="filters.type"
              name="type_filter"
              stacked
              @change="filter()"
            >
              <b-form-checkbox :unchecked-value="null" :value="'plugin'">
                Plugin
              </b-form-checkbox>
              <b-form-checkbox :unchecked-value="null" :value="'theme'">
                Thema
              </b-form-checkbox>
              <b-form-checkbox :unchecked-value="null" :value="'core'">
                Core
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-card>
      </b-col>

      <b-col md="38">
        <b-card no-body class="card-table">
          <b-table
            :items="software"
            :fields="fields"
            :busy="loading.software"
            no-local-sorting
            responsive
            class="rows-are-clickable"
            @sort-changed="sortTable"
            @row-clicked="$root.rowClickRedirect($event, 'software.show')"
          >
            <template #table-busy>
              <b-spinner variant="primary" class="align-middle mr-3" />
              <strong class="align-middle">Software wordt geladen</strong>
            </template>

            <template #empty=""> Er is geen software gevonden </template>

            <template #cell(type)="row">
              <b-badge class="text-uppercase">
                <template v-if="row.item.type === 'theme'"> Thema </template>
                <template v-else>
                  {{ row.item.type }}
                </template>
              </b-badge>
            </template>
          </b-table>
        </b-card>

        <Pagination
          v-if="softwareMeta.last_page > 1"
          :current-page="softwareMeta.current_page"
          :number-of-pages="softwareMeta.last_page"
          @change="index"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    data() {
      return {
        software: [],
        softwareMeta: {},

        filters: {
          type: null,
          terms: null,
          orderBy: null,
          orderDirection: null,
        },

        fields: [
          {
            key: "name",
            label: "Naam",
            sortable: true,
          },
          {
            key: "slug",
            label: "Slug",
            sortable: true,
          },
          {
            key: "type",
            label: "Type",
            sortable: true,
          },
        ],

        loading: {
          software: false,
        },
      };
    },

    methods: {
      filter() {
        this.$router.push({ name: "software.index", params: { page: null } });

        this.index();
      },

      sortTable(ctx) {
        this.filters.orderBy = ctx.sortBy;
        this.filters.orderDirection = ctx.sortDesc ? "desc" : "asc";

        this.index();
      },

      index() {
        this.loading.software = true;

        let params = {
          ...this.filters,
          page: this.$route.query.pagina ? this.$route.query.pagina : null,
        };

        this.$http
          .get("software", { params: params })
          .then(({ data }) => {
            this.software = data.data;
            this.softwareMeta = data.meta;
          })
          .finally(() => {
            this.loading.software = false;
          });
      },
    },

    mounted() {
      this.index();
    },
  };
</script>
