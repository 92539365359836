<template>
  <b-card no-body>
    <h2>Sites with vulnerabilities</h2>
    <b-card-body>
      <i>There should be a list here..</i>
    </b-card-body>
  </b-card>
</template>

<script>
  export default {
    data() {
      return {};
    },

    methods: {},

    mounted() {},
  };
</script>