<template>
  <div>
    <b-table-simple class="table-first-column-bold">
      <tr>
        <td>Heeft SSL</td>
        <td>
          <template v-if="dataset.has_ssl === '1'">
            <i class="far fa-circle-check text-success" />
          </template>

          <template v-else>
            <i class="far fa-circle-xmark text-danger" />
          </template>
        </td>
      </tr>

      <tr>
        <td>Geldig vanaf</td>
        <td>{{ new Date(dataset.valid_from) | date }}</td>
      </tr>

      <tr>
        <td>Geldig tot</td>
        <td>{{ new Date(dataset.valid_to) | date }}</td>
      </tr>
    </b-table-simple>
  </div>
</template>

<script>
  export default {
    props: {
      dataset: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
  };
</script>