<template>
  <span
    class="favicon"
    :style="`background-image: url(https://www.google.com/s2/favicons?sz=256&domain=${href});`"
  />
</template>

<script>
  export default {
    props: {
      href: {
        type: String,
      },
    },
  };
</script>

<style scoped>
  .vue-grid-item .favicon {
    vertical-align: middle;
  }

  .favicon {
    width: 13px;
    margin-right: 7px;
    text-decoration: none;
    display: inline-block;
    aspect-ratio: 1/1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    top: 1px;
  }
</style>
