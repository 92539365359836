<template>
  <b-modal
    hide-footer
    hide-header
    centered
    size="sm"
    id="generateKeyModal"
    bodyClass="text-center"
  >
    <b-row>
      <b-col>
        <h4>Genereer nieuwe API key</h4>
        <p>
          Weet je zeker dat je een nieuwe API key wilt genereren? Je oude key vervalt hiermee. 
        </p>
        <div>
          <b-button variant="tertiary" block @click="generateKey"> Ja </b-button>

          <b-button
            variant="light"
            class="mt-2"
            block
            @click="$root.toggleModal('generateKeyModal', 'hide')"
          >
            Nee
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
  export default {
    props: {
      username: {
        type: String,
      },
    },

    methods: {
      generateKey() {
        this.$root.toggleModal('generateKeyModal', 'hide')
        this.$emit("generateKey");
      },
    },
  };
</script>