<template>
  <b-container fluid>
    <h1>{{ site.name }}</h1>

    <div class="sync-data">
      <div title="Datum aangemaakt">
        <i class="far fa-clock mr-1" />
        {{ new Date(site.created_at) | date() }}
      </div>

      <div title="Datum laatst gesynchroniseerd">
        <i class="far fa-arrows-rotate mr-1" />
        {{ new Date(site.last_synced_at) | date() }}
      </div>
    </div>

    <div class="mb-4">
      <b-button variant="transparent" :to="{ name: 'sites.index' }">
        <i class="far fa-angle-left mr-2" />Overzicht
      </b-button>

      <b-button
        class="ml-2"
        target="_blank"
        variant="transparent"
        :href="site.metaData.url"
      >
        <i class="far fa-arrow-up-right-from-square mr-2" />Site openen
      </b-button>

      <b-button
        class="ml-2"
        target="_blank"
        variant="transparent"
        :href="site.metaData.login_url"
      >
        <i class="far fa-unlock mr-2" />Backend openen
      </b-button>
    </div>

    <b-row>
      <b-col md="36">
        <b-card class="card-table" no-body>
          <b-tabs pills card class="tabs-flush">
            <b-tab
              v-for="tab in tabs"
              no-body
              :key="tab.slug"
              :title="tab.title"
              :active="$route.params.tab === tab.slug"
              @click="updateUrl(tab)"
            >
              <component
                :is="tab.component"
                :dataset="getSiteData(tab.dataset)"
                :site="site"
                @update="update"
              />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>

      <b-col>
        <b-card>
          <b-button block>
            <i class="far fa-unlock-keyhole mr-2" />
            Admin gebruiker aanmaken
          </b-button>
          <b-button block>
            <i class="far fa-envelope mr-2" />
            Gravity Forms afzender wijzigen
          </b-button>

          <hr />

          <b-button block variant="transparent-danger">
            <i class="far fa-trash-alt mr-2" />
            Site verwijderen
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import Information from "./Tabs/_Information";
  import Plugins from "./Tabs/_Plugins";
  import PostTypes from "./Tabs/_PostTypes";
  import Server from "./Tabs/_Server";
  import Ssl from "./Tabs/_Ssl";
  import Themes from "./Tabs/_Themes";
  import Users from "./Tabs/_Users";
  import Playwright from "./Tabs/_Playwright";

  export default {
    components: {
      Information,
      Plugins,
      PostTypes,
      Server,
      Ssl,
      Themes,
      Users,
      Playwright
    },

    data() {
      return {
        showPassword: {
          normal: false,
          confirmation: false,
        },

        loading: {
          site: false,
          save: false,
        },

        errors: [],
        site: {},

        tabs: [
          {
            title: "Algemeen",
            slug: "algemeen",
            component: Information,
            dataset: "metaData",
          },
          {
            title: "Server",
            slug: "server",
            component: Server,
            dataset: "server",
          },
          {
            title: "Thema's",
            slug: "themas",
            component: Themes,
            dataset: "themes",
          },
          {
            title: "Plugins",
            slug: "plugins",
            component: Plugins,
            dataset: "plugins",
          },
          {
            title: "Post Types",
            slug: "post-types",
            component: PostTypes,
            dataset: "postTypes",
          },
          {
            title: "Gebruikers",
            slug: "gebruikers",
            component: Users,
            dataset: "users",
          },
          {
            title: "SSL",
            slug: "ssl",
            component: Ssl,
            dataset: "ssl",
          },
          {
            title: "Playwright",
            slug: "playwright",
            component: Playwright,
          },
        ],
      };
    },

    methods: {
      getSiteData(slug) {
        return this.site[slug];
      },

      get() {
        this.loading.site = true;

        this.$http
          .get(`sites/${this.$route.params.id}`)
          .then(({ data }) => {
            this.site = data.data;
          })
          .finally(() => {
            this.loading.site = false;
          });
      },

      updateUrl(tab) {
        this.$router.push({
          name: "sites.show",
          params: {
            id: this.site.id,
            tab: tab.slug,
          },
        });
      },

      update() {
        this.loading.save = true;

        this.errors = [];

        let data = {
          ...this.site,
        };

        this.$http
          .put(`sites/${this.site.id}`, data)
          .then(() => {
            this.$root.notify("Site is succesvol aangepast");
          })
          .catch(({ errors }) => {
            this.errors = this.$options.filters.setErrors(errors);
          })
          .finally(() => {
            this.loading.save = false;
          });
      },

      destroy() {
        this.$http.delete(`sites/${this.site.id}`).then(() => {
          this.$root.notify("Site is succesvol verwijderd");

          this.$router.push({ name: "sites.index" });
        });
      },
    },

    mounted() {
      this.get();
    },
  };
</script>
