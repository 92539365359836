var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{attrs:{"no-body":""}},[_c('h2',[_vm._v("Nieuwe vulnerabilities")]),_c('b-card-body',[_c('ul',_vm._l((_vm.vulnerabilities),function(vulnerability){return _c('li',{key:vulnerability.id},[_c('div',[_c('span',{staticClass:"item-title"},[_vm._v(" "+_vm._s(vulnerability.software.name)+" ")]),_c('span',{staticClass:"subtext"},[_vm._v(" "+_vm._s(_vm._f("date")(new Date(vulnerability.created_at)))+" ")])]),_c('div',{staticClass:"item-controls"},[_c('router-link',{staticClass:"item-control",attrs:{"to":{
              name: 'software.show.vulnerability',
              params: {
                id: vulnerability.software.id,
                vulnerabilityId: vulnerability.id,
              },
            },"id":`openInfo${vulnerability.id}`}},[_c('i',{staticClass:"far fa-arrow-right"})]),_c('b-tooltip',{attrs:{"target":`openInfo${vulnerability.id}`,"triggers":"hover","placement":"top","boundary":"window"}},[_vm._v(" Informatie bekijken ")])],1)])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }