<template>
  <b-modal
    hide-footer
    hide-header
    centered
    size="md"
    id="showKeyModal"
    bodyClass="text-center"
  >
    <b-row>
      <b-col>
        <h4>API key</h4>
        <p>
          De nieuwe API key vind je hieronder. Deze wordt maar 1 keer getoond dus sla deze zelf op.
        </p>
        <code>
          {{token}}
        </code>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
  export default {
    props: {
      token: {
        type: String,
      },
    },
  };
</script>