<template>
  <div
    id="app"
    :class="{
      main: showNavigation,
      scrolling: scrolling,
    }"
  >
    <vue-progress-bar />
    <Navigation v-if="showNavigation" />
    <router-view />
  </div>
</template>

<style lang="sass">
@import "@/assets/scss/style"
</style>

<script>
  import { mapGetters } from "vuex";
  import Navigation from "@/components/Navigation";

  export default {
    components: {
      Navigation,
    },

    computed: {
      ...mapGetters({
        auth: "auth/user",
      }),

      showNavigation() {
        const hideNavigationOn = ["page-not-found", "auth.login"];

        return !hideNavigationOn.includes(this.$route.name);
      },
    },

    data() {
      return {
        scrolling: false,
      };
    },

    methods: {
      setCookie() {
        this.$http.get("/sanctum/csrf-cookie");
      },

      handleSCroll() {
        this.scrolling = window.scrollY > 0;
      },
    },

    created() {
      this.$http.interceptors.request.use((config) => {
        this.$Progress.start();

        return config;
      });

      this.$http.interceptors.response.use(
        (response) => {
          this.$Progress.finish();
          return Promise.resolve(response);
        },
        ({ response }) => {
          this.$Progress.finish();

          const urlsToSkip = ["addresses/check-address"];

          if (!urlsToSkip.includes(response.config.url)) {
            this.$root.notify(
              "Er is iets misgegaan, probeer het later opnieuw!",
              "danger"
            );
          }

          return Promise.reject(response.data);
        }
      );
    },

    mounted() {
      window.addEventListener("scroll", this.handleSCroll);
      this.setCookie();
    },
  };
</script>