<template>
  <b-breadcrumb>
    <template v-if="breadcrumbs.length">
      <b-breadcrumb-item
        v-for="breadcrumb in breadcrumbs"
        :key="breadcrumb.text"
        :to="breadcrumb.to"
      >
        {{ breadcrumb.text }}
      </b-breadcrumb-item>
    </template>

    <b-breadcrumb-item active>
      <slot name="activeItem">
        {{ activeItem }}
      </slot>
    </b-breadcrumb-item>
  </b-breadcrumb>
</template>

<script>
  export default {
    props: {
      activeItem: {
        type: String,
      },

      breadcrumbs: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
  };
</script>
