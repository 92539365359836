/**
 * Imports
 */
require('./filters/index')

import Vue from 'vue'

import '@/plugins/bootstrap-vue'
import VueProgressBar from 'vue-progressbar'
import Gravatar from 'vue-gravatar';

import App from '@/App'
import router from '@/router'

import store from '@/store'

import Axios from 'axios';

import Input from '@/components/Input'
import SearchInput from '@/components/SearchInput'
import SearchInputExtended from '@/components/SearchInputExtended'
import AutoCompleteInput from '@/components/AutoCompleteInput'
import TextArea from '@/components/TextArea'
import Pagination from '@/components/Pagination'
import Breadcrumbs from '@/components/Breadcrumbs'
import ButtonSpinner from '@/components/ButtonSpinner'
import Favicon from '@/components/Favicon'

import { RoleType, RoleTypeString } from '@/enums/User/Role'

/**
 * Basic settings
 */
Vue.config.productionTip = false
Vue.prototype.$http = Axios;

/**
 * Axios settings
 */
Axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Axios.defaults.withCredentials = true;

/**
 * Custom Middleware
 */
router.beforeEach((to, from, next) => {
  const allowedRoutes = [
    'page-not-found',
    'auth.login',
  ];

  /**
   * Redirection for unauthenticated Clients
   */
  if (
    !to.name
    || !allowedRoutes.includes(to.name)
    && !store.getters['auth/authenticated']
  ) {
    next({ name: 'auth.login', query: { referer: to.fullPath } });
  } else if (
    (to.name === 'auth.login')
    && store.getters['auth/authenticated']
  ) {
    next({ name: 'dashboard' });
  } else {
    next();
  }

  /**
   * Set page title
   */
  const title = 'Nordique Dash';
  document.title = to.meta.title ? `${to.meta.title} | ${title}` : title

  var classList = document.body.classList;

  while (classList.length > 0) {
    classList.remove(classList.item(0));
  }

  classList.add(to.name.replace('.', '-'))
});

/**
 * Assing Vue Plugins
 */
Vue.use(VueProgressBar, {
  color: '#172b4e',
  failedColor: '#e76f51',
  thickness: '4px',
});

/**
 * Global Accessible Components
 */
Vue.component('Avatar', Gravatar);
Vue.component('Input', Input);
Vue.component('SearchInput', SearchInput);
Vue.component('SearchInputExtended', SearchInputExtended);
Vue.component('TextArea', TextArea);
Vue.component('Pagination', Pagination);
Vue.component('Breadcrumbs', Breadcrumbs);
Vue.component('ButtonSpinner', ButtonSpinner);
Vue.component('AutoCompleteInput', AutoCompleteInput);
Vue.component('Favicon', Favicon);

/**
 * Initialize Vue
 */
store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
  new Vue({
    router,
    store,

    data() {
      return {
        RoleType,
        RoleTypeString,
      }
    },

    methods: {
      toggleModal(id, action = "show") {
        if (action === "show") {
          this.$bvModal.show(id);
        } else {
          this.$bvModal.hide(id);
        }
      },

      showConfirm(message, callback, fallback, cancelText = 'Cancel', okText = 'OK') {
        this.$bvModal
          .msgBoxConfirm(message, {
            size: 'sm',
            okTitle: okText,
            cancelTitle: cancelText,
            cancelVariant: 'light',
            title: 'Bevestiging',
            centered: true,
            dialogClass: 'modal-confirmation',
          })
          .then((value) => {
            if (value === true) {
              callback();
            }
            else {
              if (fallback) {
                fallback();
              }
            }
          });
      },

      notify(message, variant = 'success') {
        let icon = 'fa-circle-check';

        if (variant === 'danger') {
          icon = 'fa-circle-exclamation'
        }

        const h = this.$createElement;

        const toast = this.$bvToast;

        const vNodesMsg = h('p', {
          class: ['d-flex align-items-center']
        },
          [
            h('i', {
              class: [
                'fas mr-2', icon, `text-${variant}`
              ]
            }),
            message,
          ]
        );

        this.$bvToast.toast([vNodesMsg], {
          solid: true,
          autoHideDelay: 5000,
          toaster: 'b-toaster-bottom-right',
          noCloseButton: false,
          variant: variant,
        });
      },

      copyData(text) {
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand("copy", true);
        document.body.removeChild(dummy);
      },

      applyFilters(filters) {
        this.$router.push({
          query: filters,
        });
      },

      setFilters(filters) {
        if (this.$route.query.tab) {
          this.$route.query.tab = parseInt(this.$route.query.tab)
        }

        return { ...filters, ...this.$route.query };
      },

      rowClickRedirect(record, routeName, extraParams = null) {
        this.$router.push({
          name: routeName,
          params: {
            id: record.id,
            ...extraParams
          },
        });
      },

      linkGen(pageNum) {
        return pageNum === 1 ? "?" : `?pagina=${pageNum}`;
      },
    },

    render: h => h(App)
  }).$mount('#app')
});
