<template>
  <div>
    <b-table-simple class="table-first-column-bold">
      <b-tbody>
        <b-tr>
          <b-td>Naam</b-td>
          <b-td>{{ dataset.name }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>Beschrijving</b-td>
          <b-td>{{ dataset.description || "-" }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>URL</b-td>
          <b-td>
            <a :href="dataset.url" target="_blank">
              {{ dataset.url }}
            </a>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td>Admin e-mail</b-td>
          <b-td>{{ dataset.admin_email }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>WordPress versie</b-td>
          <b-td>{{ dataset.version }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>RSS Url</b-td>
          <b-td>
            <a :href="dataset.rss2_url" target="_blank">
              {{ dataset.rss2_url }}
            </a>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td>Taal</b-td>
          <b-td>{{ dataset.language }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>Publiek</b-td>
          <b-td>{{ dataset.public }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>Login URL</b-td>
          <b-td>
            <a :href="dataset.login_url" target="_blank">
              {{ dataset.login_url }}
            </a>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td>Database prefix</b-td>
          <b-td>{{ dataset.wpdb_prefix }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>Multisite</b-td>
          <b-td>{{ dataset.multisite }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>Auto core update</b-td>
          <b-td>{{ dataset.auto_update_core }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>Auto update uitgeschakeld</b-td>
          <b-td>{{ dataset.auto_update_disabled }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>WP Debug</b-td>
          <b-td>{{ dataset.wp_debug }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>Revisies</b-td>
          <b-td>{{ dataset.revisions }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>Auto save periode (in sec.)</b-td>
          <b-td>{{ dataset.autosave }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
  export default {
    props: {
      dataset: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
  };
</script>