<template>
  <b-card no-body>
    <h2>Nieuwe sites</h2>
    <b-card-body>
      <ul>
        <li v-for="site of sites" :key="site.id">
          <div>
            <Favicon :href="site.url" />
            <span class="item-title">
              {{ site.name }}
            </span>
            <span class="subtext">
              {{ new Date(site.created_at) | date }}
            </span>
          </div>

          <div class="item-controls">
            <router-link
              :to="{
                name: 'sites.show',
                params: {
                  id: site.id,
                },
              }"
              class="item-control"
              :id="`openInfo${site.id}`"
            >
              <i class="far fa-arrow-right" />
            </router-link>

            <b-tooltip
              :target="`openInfo${site.id}`"
              triggers="hover"
              placement="top"
              boundary="window"
            >
              Informatie bekijken
            </b-tooltip>
            <a
              :href="site.url"
              target="_blank"
              class="item-control"
              :id="`openSite${site.id}`"
            >
              <i class="far fa-arrow-up-right-from-square" />
            </a>
            <b-tooltip
              :target="`openSite${site.id}`"
              triggers="hover"
              placement="top"
              boundary="window"
            >
              Site openen
            </b-tooltip>
            <a
              :href="site.metaData.login_url"
              class="column-action"
              target="_blank"
              :id="`openAdmin${site.id}`"
            >
              <i class="far fa-unlock" />
            </a>
            <b-tooltip
              :target="`openAdmin${site.id}`"
              triggers="hover"
              placement="top"
              boundary="window"
            >
              Backend openen
            </b-tooltip>
          </div>
        </li>
      </ul>
    </b-card-body>
  </b-card>
</template>

<script>
  export default {
    data() {
      return {
        sites: [],
      };
    },

    methods: {
      getSites() {
        let params = {
          orderBy: "created_at",
          orderDirection: "DESC",
          limit: 10,
        };

        this.$http.get("sites", { params: params }).then(({ data }) => {
          this.sites = data.data;
        });
      },
    },

    mounted() {
      this.getSites();
    },
  };
</script>