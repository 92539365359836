import Vue from 'vue';
import { createDateFilter } from "vue-date-fns";
import locale from "date-fns/locale/nl";

Vue.filter('toSlug', function (value) {
  if (!value) return '';

  var slug = '';

  slug = value.toLowerCase()
    .replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e')
    .replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a')
    .replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o')
    .replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u')
    .replace(/đ/gi, 'd')
    .replace(/\s*$/g, '')
    .replace(/\s+/g, '-');

  return slug;
});

Vue.filter('googleMapsUrl', function (addressData) {
  const baseUrl = 'https://www.google.com/maps/search/?api=1&query=';

  let url = `${baseUrl}${addressData.street}+${addressData.house_number}`;

  if (addressData.house_number_addition) {
    url += `+${addressData.house_number_addition}`;
  }

  if (addressData.city) {
    url += `+${addressData.city}`;
  }

  return url;
});

Vue.filter('wrapArray', function (value) {
  return [value];
});

Vue.filter('objectToArray', function (data) {
  let converted = [];

  for (const [key, value] of Object.entries(data)) {
    converted[key] = value
  }

  return converted;
});

Vue.filter('percentageDiff', function (value, oldValue) {
  let percentage = Math.abs((value - oldValue) / oldValue * 100);

  if (isFinite(percentage)) {
    return parseFloat(percentage).toFixed(2);
  } else {
    return '>100';
  }

});

Vue.filter('formatNumber', function (value, decimals = null, thousandSeperators = false) {
  if (decimals !== null) {
    value = parseFloat(value).toFixed(decimals);
  }

  value = value.toString().replace('.', ',');

  if (thousandSeperators) {
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  return value;
});

Vue.filter('setErrors', function (responseErrors) {
  if (!responseErrors) return

  let errors = {};

  for (const [key, value] of Object.entries(responseErrors)) {
    if (Array.isArray(value[0])) {
      errors[key] = value[0][0];
    } else {
      errors[key] = value[0];
    }

  }

  return errors;
});

Vue.filter('clearErrors', function (errors) {
  for (const [key] of Object.entries(errors)) {
    errors[key] = null;
  }

  return errors;
});

Vue.filter("date", createDateFilter("dd-MM-yyyy HH:mm:ss", { locale }));

Vue.filter('urlify', function (text) {
  const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;

  return text.replace(urlRegex, (url) => {

    if (url.startsWith('http')) {
      return `<a target="_blank" href="${url}"> ${url}</a> `;
    }

    return `<a target="_blank" href="//${url}"> ${url}</a> `;
  });
});