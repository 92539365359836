<template>
  <b-card no-body>
    <span class="card-timer">{{ timer }}</span>
    <h2>Sites met downtime</h2>

    <b-card-body>
      <ul>
        <li v-for="monitor of monitors" :key="monitor.id">
          <div>
            <Favicon :href="monitor.url" />
            <span class="item-title">
              {{ monitor.friendly_name }}
            </span>
            <span class="subtext">
              Sinds: {{ new Date(monitor.logs[0].datetime * 1000) | date }}
            </span>
          </div>

          <div class="item-controls">
            <a href="#" class="item-control" :id="`openInfo${monitor.id}`">
              <i class="far fa-arrow-right" />
            </a>
            <b-tooltip
              :target="`openInfo${monitor.id}`"
              triggers="hover"
              placement="top"
              boundary="window"
            >
              Informatie bekijken
            </b-tooltip>
            <a
              :href="monitor.url"
              target="_blank"
              class="item-control"
              :id="`openSite${monitor.id}`"
            >
              <i class="far fa-arrow-up-right-from-square" />
            </a>
            <b-tooltip
              :target="`openSite${monitor.id}`"
              triggers="hover"
              placement="top"
              boundary="window"
            >
              Site openen
            </b-tooltip>
          </div>
        </li>
      </ul>
    </b-card-body>
  </b-card>
</template>

<script>
  export default {
    data() {
      return {
        monitors: [],

        timer: 60,
      };
    },

    methods: {
      getMonitors() {
        this.$http.get("down-monitors").then(({ data }) => {
          this.monitors = data.monitors;
        });
      },

      handelTimer() {
        if (!this.timer) {
          this.getMonitors();
        }

        this.timer = this.timer ? this.timer - 1 : 60;
      },
    },

    mounted() {
      this.getMonitors();

      setInterval(this.handelTimer, 1000);
    },
  };
</script>