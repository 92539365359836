<template>
  <b-card no-body>
    <h2>Nieuwe vulnerabilities</h2>
    <b-card-body>
      <ul>
        <li v-for="vulnerability of vulnerabilities" :key="vulnerability.id">
          <div>
            <span class="item-title">
              {{ vulnerability.software.name }}
            </span>
            <span class="subtext">
              {{ new Date(vulnerability.created_at) | date }}
            </span>
          </div>

          <div class="item-controls">
            <router-link
              :to="{
                name: 'software.show.vulnerability',
                params: {
                  id: vulnerability.software.id,
                  vulnerabilityId: vulnerability.id,
                },
              }"
              class="item-control"
              :id="`openInfo${vulnerability.id}`"
            >
              <i class="far fa-arrow-right" />
            </router-link>

            <b-tooltip
              :target="`openInfo${vulnerability.id}`"
              triggers="hover"
              placement="top"
              boundary="window"
            >
              Informatie bekijken
            </b-tooltip>
          </div>
        </li>
      </ul>
    </b-card-body>
  </b-card>
</template>

<script>
  export default {
    data() {
      return {
        vulnerabilities: [],
      };
    },

    methods: {
      getVulnerabilities() {
        let params = {
          orderBy: "created_at",
          orderDirection: "DESC",
          limit: 10,
        };

        this.$http.get("vulnerabilities", { params: params }).then(({ data }) => {
          this.vulnerabilities = data.data;
        });
      },
    },

    mounted() {
      this.getVulnerabilities();
    },
  };
</script>