<template>
  <b-container fluid>
    <h1>{{ $route.meta.title }}</h1>

    <div class="mb-4">
      <b-button variant="transparent" :to="{ name: 'users.index' }">
        <i class="far fa-angle-left mr-2" />Overzicht
      </b-button>
    </div>

    <b-row>
      <b-col xl="36">
        <b-card>
          <b-row>
            <b-col>
              <div>
                <b-row>
                  <b-col>
                    <Input
                      v-model="user.first_name"
                      label="Voornaam"
                      placeholder="Piet"
                      :id="`first_name`"
                      :error="errors['first_name']"
                    />
                  </b-col>

                  <b-col>
                    <Input
                      v-model="user.last_name"
                      label="Achternaam"
                      placeholder="Hoane"
                      :id="`last_name`"
                      :error="errors['last_name']"
                    />
                  </b-col>
                </b-row>

                <Input
                  v-model="user.email"
                  label="E-mailadres"
                  placeholder="piet.hoane@nordique.nl"
                  :id="`email`"
                  :error="errors['email']"
                />

                <template v-if="auth.role === $root.RoleType.ADMIN">
                  <b-form-group label="Rol">
                    <b-select v-model="user.role">
                      <b-select-option :value="1">Gebruiker</b-select-option>
                      <b-select-option :value="2">Beheerder</b-select-option>
                    </b-select>
                  </b-form-group>
                </template>

                <div class="password-preview">
                  <Input
                    :type="!showPassword.normal ? 'password' : 'text'"
                    :id="`password`"
                    :error="errors['password']"
                    v-model="user.password"
                    label="Wachtwoord"
                    placeholder="Vul hier optioneel een nieuw wachtwoord in (optioneel)"
                  />
                  <span
                    :class="{ active: showPassword.normal }"
                    @click="showPassword.normal = !showPassword.normal"
                  >
                    <i class="far fa-eye" />
                  </span>
                </div>

                <div class="password-preview">
                  <Input
                    :type="!showPassword.confirmation ? 'password' : 'text'"
                    :id="`password-confirmation`"
                    :error="errors['password_confirmation']"
                    v-model="user.password_confirmation"
                    label="Wachtwoord herhalen"
                    placeholder="Herhaal hier het nieuwe wachtwoord (optioneel)"
                  />
                  <span
                    :class="{ active: showPassword.confirmation }"
                    @click="
                      showPassword.confirmation = !showPassword.confirmation
                    "
                  >
                    <i class="far fa-eye" />
                  </span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col>
        <b-card>
          <button-spinner class="mr-2" block :loading="loading" @click="create">
            <i class="far fa-save mr-2" />Opslaan
          </button-spinner>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        showPassword: {
          normal: false,
          confirmation: false,
        },

        loading: false,

        errors: [],
        user: {
          role: 1,
        },
      };
    },

    computed: {
      ...mapGetters({
        auth: "auth/user",
      }),
    },

    methods: {
      create() {
        this.loading = true;

        this.errors = [];

        let data = { ...this.user };

        this.$http
          .post("users", data)
          .then(({ data }) => {
            this.$root.notify("Gebruiker is succesvol aangemaakt");

            this.$router.push({
              name: "users.show",
              params: {
                id: data.data.id,
              },
            });
          })
          .catch(({ errors }) => {
            this.errors = this.$options.filters.setErrors(errors);
          })
          .finally(() => {
            this.loading = false;
          });
      },
    },
  };
</script>
