<template>
  <b-container fluid class="h-100 login">
    <b-row class="align-items-center justify-content-center h-100">
      <b-col md="14">
        <Logo />

        <b-card>
          <div class="login-welcome">
            <h1>Welkom!</h1>

            <p>Login met je persoonlijke gegevens.</p>
          </div>

          <b-alert variant="danger" class="mb-3" show v-if="errors.default">
            {{ errors.default }}
          </b-alert>

          <b-form @submit.prevent="signIn">
            <Input
              type="email"
              :id="`user-email`"
              :error="errors['email']"
              v-model="credentials['email']"
              placeholder="E-mail"
            />
            <div class="password-preview">
              <Input
                :type="!showPassword ? 'password' : 'text'"
                :id="`user-password`"
                :error="errors['password']"
                v-model="credentials['password']"
                placeholder="Wachtwoord"
              />
              <span
                :class="{ active: showPassword }"
                @click="showPassword = !showPassword"
              >
                <i class="far fa-eye" />
              </span>
            </div>

            <button-spinner
              block
              :loading="loading"
              type="submit"
              variant="primary"
            >
              Inloggen
            </button-spinner>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { mapActions } from "vuex";
  import Logo from "@/components/Logo";

  export default {
    components: {
      Logo,
    },

    data() {
      return {
        showPassword: false,

        credentials: {
          email: null,
          password: null,
        },

        loading: false,

        errors: {
          default: null,
        },
      };
    },

    methods: {
      ...mapActions({
        signInAction: "auth/signIn",
      }),

      signIn() {
        this.loading = true;

        this.errors = this.$options.filters.clearErrors(this.errors);

        this.signInAction(this.credentials)
          .then(() => {
            if (this.$route.query.referer) {
              this.$router.push({
                path: this.$route.query.referer,
              });
            } else {
              this.$router.push({
                name: "dashboard",
              });
            }
          })
          .catch((errors) => {
            this.errors = this.$options.filters.setErrors(errors.errors);
          })
          .finally(() => {
            this.loading = false;
          });
      },
    },
  };
</script>