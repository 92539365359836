<template>
  <b-container fluid>
    <h1>{{ $route.meta.title }}</h1>

    <div class="mb-4">
      <b-button variant="transparent" :to="{ name: 'users.create' }">
        <i class="far fa-circle-plus mr-2" />Gebruiker aanmaken
      </b-button>
    </div>

    <b-card no-body class="card-table">
      <b-table
        :fields="fields"
        :items="users"
        :busy="loading.users"
        responsive
        class="rows-are-clickable"
        @row-clicked="$root.rowClickRedirect($event, 'users.show')"
      >
        <template #table-busy>
          <b-spinner variant="primary" class="align-middle mr-3" />
          <strong class="align-middle">Gebruikers worden geladen</strong>
        </template>

        <template #empty=""> Er zijn geen gebruikers gevonden </template>

        <template #cell(role)="row">
          {{ $root.RoleTypeString[row.item.role] }}
        </template>
      </b-table>
    </b-card>
  </b-container>
</template>

<script>
  export default {
    data() {
      return {
        users: [],
        usersMeta: {},

        fields: [
          {
            key: "formatted_name",
          },
          {
            key: "role",
          },
          {
            key: "email",
          },
        ],

        loading: {
          users: false,
        },
      };
    },

    methods: {
      index() {
        this.loading.users = true;

        this.$http
          .get("users")
          .then(({ data }) => {
            this.users = data.data;
            this.usersMeta = data.meta;
          })
          .then(() => {
            this.loading.users = false;
          });
      },
    },

    mounted() {
      this.index();
    },
  };
</script>
