<template>
  <div>
    <b-table :fields="fields" :items="dataset">
      <template #cell(name)="row">
        <div>
          {{ row.item.name }}
        </div>

        <div class="column-actions">
          <a
            v-if="row.item.uri"
            class="column-action"
            target="_blank"
            :href="row.item.uri"
          >
            <i class="far fa-arrow-up-right-from-square" />
          </a>
        </div>
      </template>

      <template #cell(active)="row">
        <template v-if="row.item.activated">
          <i class="far fa-circle-check text-success" />
        </template>

        <template v-else>
          <i class="far fa-circle-xmark text-danger" />
        </template>
      </template>
    </b-table>
  </div>
</template>

<script>
  export default {
    props: {
      dataset: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },

    data() {
      return {
        fields: [
          { key: "name", label: "Naam" },
          { key: "version", label: "Versie" },
          { key: "author", label: "Auteur" },
          {
            key: "active",
            label: "Actief",
            thClass: "text-center",
            tdClass: "text-center",
          },
        ],
      };
    },
  };
</script>