<template>
  <b-container fluid>
    <h1>{{ $route.meta.title }}</h1>

    <div class="mb-4">
      <b-button variant="transparent" :to="{ name: 'users.index' }">
        <i class="far fa-angle-left mr-2" />Overzicht
      </b-button>
    </div>

    <b-row>
      <b-col xl="36">
        <b-card>
          <b-row>
            <b-col>
              <div>
                <b-row>
                  <b-col>
                    <Input
                      v-model="user.first_name"
                      label="Voornaam"
                      placeholder="Piet"
                      :id="`first_name`"
                      :error="errors['first_name']"
                    />
                  </b-col>

                  <b-col>
                    <Input
                      v-model="user.last_name"
                      label="Achternaam"
                      placeholder="Hoane"
                      :id="`last_name`"
                      :error="errors['last_name']"
                    />
                  </b-col>
                </b-row>

                <Input
                  v-model="user.email"
                  label="E-mailadres"
                  placeholder="piet.hoane@nordique.nl"
                  :id="`email`"
                  :error="errors['email']"
                />

                <template v-if="auth.role === $root.RoleType.ADMIN">
                  <b-form-group label="Rol">
                    <b-select v-model="user.role">
                      <b-select-option :value="1">Gebruiker</b-select-option>
                      <b-select-option :value="2">Beheerder</b-select-option>
                    </b-select>
                  </b-form-group>
                </template>

                <div class="password-preview">
                  <Input
                    :type="!showPassword.normal ? 'password' : 'text'"
                    :id="`password`"
                    :error="errors['password']"
                    v-model="user.password"
                    label="Wachtwoord"
                    placeholder="Vul hier optioneel een nieuw wachtwoord in (optioneel)"
                  />
                  <span
                    :class="{ active: showPassword.normal }"
                    @click="showPassword.normal = !showPassword.normal"
                  >
                    <i class="far fa-eye" />
                  </span>
                </div>

                <div class="password-preview">
                  <Input
                    :type="!showPassword.confirmation ? 'password' : 'text'"
                    :id="`password-confirmation`"
                    :error="errors['password_confirmation']"
                    v-model="user.password_confirmation"
                    label="Wachtwoord herhalen"
                    placeholder="Herhaal hier het nieuwe wachtwoord (optioneel)"
                  />
                  <span
                    :class="{ active: showPassword.confirmation }"
                    @click="
                      showPassword.confirmation = !showPassword.confirmation
                    "
                  >
                    <i class="far fa-eye" />
                  </span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col>
        <b-card>
          <button-spinner
            class="mr-2"
            block
            :loading="loading.save"
            @click="update"
          >
            <i class="far fa-save mr-2" />Opslaan
          </button-spinner>

          <button-spinner
              class="mr-2"
              block
              :loading="loading.token"
              @click="$root.toggleModal('generateKeyModal')"
          >
            <i class="far fa-key mr-2" />Genereer nieuwe API key
          </button-spinner>

          <hr />

          <b-button
            variant="transparent-danger"
            block
            @click="$root.toggleModal('removeUserModal')"
          >
            <i class="far fa-trash-alt mr-2" />
            Gebruiker verwijderen
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <GenerateKey @generateKey="generateKey" />
    <ShowKey :token="token" />
    <RemoveUser :username="user.formatted_name" @removeUser="destroy" />
  </b-container>
</template>

<script>
  import { mapGetters } from "vuex";
  import RemoveUser from "./Modals/RemoveUser";
  import GenerateKey from "./Modals/GenerateKey";
  import ShowKey from "@/views/Users/Modals/ShowKey.vue";

  export default {
    components: {
      ShowKey,
      RemoveUser,
      GenerateKey
    },

    data() {
      return {
        showPassword: {
          normal: false,
          confirmation: false,
        },

        loading: {
          user: false,
          save: false,
          token: false
        },

        errors: [],
        user: {},
        token: ""
      };
    },

    computed: {
      ...mapGetters({
        auth: "auth/user",
      }),
    },

    methods: {
      get() {
        this.loading.user = true;

        let id = this.$route.params.id || this.auth.id;

        this.$http
          .get(`users/${id}`)
          .then(({ data }) => {
            this.user = data.data;
          })
          .finally(() => {
            this.loading.user = false;
          });
      },

      generateKey() {
        this.loading.token = true;

        this.$http
            .post(`users/${this.user.id}/generate-key`)
            .then((data) => {
              this.$root.notify("Nieuwe API key succesvol gegenereerd");
              this.token = data.data.token;
              this.$root.toggleModal('showKeyModal', 'show');
            })
            .finally(() => {
              this.loading.token = false;
            });
      },

      update() {
        this.loading.save = true;

        this.errors = [];

        let data = {
          ...this.user,
        };

        this.$http
          .put(`users/${this.user.id}`, data)
          .then(() => {
            this.$root.notify("Gebruiker is succesvol aangepast");
          })
          .catch(({ errors }) => {
            this.errors = this.$options.filters.setErrors(errors);
          })
          .finally(() => {
            this.loading.save = false;
          });
      },

      destroy() {
        this.$http.delete(`users/${this.user.id}`).then(() => {
          this.$root.notify("Gebruiker is succesvol verwijderd");

          this.$router.push({ name: "users.index" });
        });
      },
    },

    mounted() {
      this.get();
    },
  };
</script>
