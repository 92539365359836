<template>
  <b-button
    :active="active"
    :activeClass="activeClass"
    :append="append"
    :block="block"
    :disabled="loading ? true : disabled"
    :exact="exact"
    :exactActiveClass="exactActiveClass"
    :exactPath="exactPath"
    :exactPathActiveClass="exactPathActiveClass"
    :href="href"
    :noPrefetch="noPrefetch"
    :pill="pill"
    :prefetch="prefetch"
    :pressed="pressed"
    :rel="rel"
    :replace="replace"
    :routerComponentName="routerComponentName"
    :size="size"
    :squared="squared"
    :tag="tag"
    :target="target"
    :to="to"
    :type="type"
    :variant="variant"
    :class="['has-spinner', { loading: loading }]"
    @click="$emit('click', $event)"
  >
    <span class="btn-spinner" v-show="loading">
      <b-spinner small />
    </span>
    <span class="btn-content">
      <slot />
    </span>
  </b-button>
</template>

<script>
  export default {
    props: {
      /**
       * 	When set to `true`, places the component in the active state with active styling
       */
      active: {
        type: Boolean,
        default: () => {
          return false;
        },
      },

      /**
       * <router-link> prop: Configure the active CSS class applied when the link is active. Typically you will want to set this to class name 'active'
       */
      activeClass: {
        type: String,
        default: () => {
          return "";
        },
      },

      /**
       * <router-link> prop: Setting append prop always appends the relative path to the current path
       */
      append: {
        type: Boolean,
        default: () => {
          return false;
        },
      },

      /**
       * Renders a 100% width button (expands to the width of its parent container)
       */
      block: {
        type: Boolean,
        default: () => {
          return false;
        },
      },

      /**
       * When set to `true`, disables the component's functionality and places it in a disabled state
       */
      disabled: {
        type: Boolean,
        default: () => {
          return false;
        },
      },

      /**
       * <router-link> prop: The default active class matching behavior is inclusive match. Setting this prop forces the mode to exactly match the route
       */
      exact: {
        type: Boolean,
        default: () => {
          return false;
        },
      },

      /**
       * <router-link> prop: Configure the active CSS class applied when the link is active with exact match. Typically you will want to set this to class name 'active'
       */
      exactActiveClass: {
        type: String,
        default: () => {
          return "";
        },
      },

      /**
       * <router-link> prop: Allows matching only using the path section of the url, effectively ignoring the query and the hash sections
       */
      exactPath: {
        type: Boolean,
        default: () => {
          return false;
        },
      },

      /**
       * <router-link> prop: Configure the active CSS class applied when the link is active with exact path match. Typically you will want to set this to class name 'active'
       */
      exactPathActiveClass: {
        type: String,
        default: () => {
          return "";
        },
      },

      /**
       * <b-link> prop: Denotes the target URL of the link for standard a links
       */
      href: {
        type: String,
        default: () => {
          return "";
        },
      },

      /**
       * Renders a spinner inside the button, this also disables the button
       */
      loading: {
        type: Boolean,
        default: () => {
          return false;
        },
      },

      /**
       * <nuxt-link> prop: To improve the responsiveness of your Nuxt.js applications, when the link will be displayed within the viewport, Nuxt.js will automatically prefetch the code splitted page. Setting `no-prefetch` will disabled this feature for the specific link
       */
      noPrefetch: {
        type: Boolean,
        default: () => {
          return false;
        },
      },

      /**
       * Renders the button with the pill style appearance when set to 'true'
       */
      pill: {
        type: Boolean,
        default: () => {
          return false;
        },
      },

      /**
       * <nuxt-link> prop: To improve the responsiveness of your Nuxt.js applications, when the link will be displayed within the viewport, Nuxt.js will automatically prefetch the code splitted page. Setting `prefetch` to `true` or `false` will overwrite the default value of `router.prefetchLinks`
       */
      prefetch: {
        type: Boolean,
        default: () => {
          return null;
        },
      },

      /**
       * When set to 'true', gives the button the appearance of being pressed and adds attribute 'aria-pressed="true"'. When set to `false` adds attribute 'aria-pressed="false"'. Tri-state prop. Syncable with the .sync modifier
       */
      pressed: {
        type: Boolean,
        default: () => {
          return null;
        },
      },

      /**
       * <b-link> prop: Sets the `rel` attribute on the rendered link
       */
      rel: {
        type: String,
        default: () => {
          return null;
        },
      },

      /**
       * <router-link> prop: Setting the replace prop will call `router.replace()` instead of `router.push()` when clicked, so the navigation will not leave a history record
       */
      replace: {
        type: Boolean,
        default: () => {
          return false;
        },
      },

      /**
       * <b-link> prop: BootstrapVue auto detects between `<router-link>` and `<nuxt-link>`. In cases where you want to use a 3rd party link component based on `<router-link>`, set this prop to the component name. e.g. set it to 'g-link' if you are using Gridsome (note only `<router-link>` specific props are passed to the component)
       */
      routerComponentName: {
        type: String,
        default: () => {
          return "";
        },
      },

      /**
       * Set the size of the component's appearance. 'sm', 'md' (default), or 'lg'
       */
      size: {
        type: String,
        default: () => {
          return "";
        },
      },

      /**
       * Renders the button with non-rounded corners when set to 'true'
       */
      squared: {
        type: Boolean,
        default: () => {
          return false;
        },
      },

      /**
       * Specify the HTML tag to render instead of the default tag
       */
      tag: {
        type: String,
        default: () => {
          return "button";
        },
      },

      /**
       * <b-link> prop: Sets the `target` attribute on the rendered link
       */
      target: {
        type: String,
        default: () => {
          return "_self";
        },
      },

      /**
       * <router-link> prop: Denotes the target route of the link. When clicked, the value of the to prop will be passed to `router.push()` internally, so the value can be either a string or a Location descriptor object
       */
      to: {
        type: [Object, String],
        default: () => {
          return "";
        },
      },

      /**
       * The value to set the button's 'type' attribute to. Can be one of 'button', 'submit', or 'reset'
       */
      type: {
        type: String,
        default: () => {
          return "button";
        },
      },

      /**
       * Applies one of the Bootstrap theme color variants to the component
       */
      variant: {
        type: String,
        default: () => {
          return "secondary";
        },
      },
    },
  };
</script>