<template>
  <b-modal
    hide-footer
    hide-header
    centered
    size="sm"
    id="removeUserModal"
    bodyClass="text-center"
  >
    <b-row>
      <b-col>
        <h4>Gebruiker verwijderen</h4>
        <p>
          Weet je zeker dat je {{ username || "de gebruiker" }} wil verwijderen?
        </p>
        <div>
          <b-button variant="tertiary" block @click="removeUser"> Ja </b-button>

          <b-button
            variant="light"
            class="mt-2"
            block
            @click="$root.toggleModal('removeUserModal', 'hide')"
          >
            Nee
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
  export default {
    props: {
      username: {
        type: String,
      },
    },

    methods: {
      removeUser() {
        this.$emit("removeUser");
      },
    },
  };
</script>