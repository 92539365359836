<template>
  <b-container fluid>
    <h1>{{ software.name }}</h1>

    <div class="mb-4">
      <b-button variant="transparent" :to="{ name: 'software.index' }">
        <i class="far fa-angle-left mr-2" />Overzicht
      </b-button>

      <b-button
        class="ml-2"
        v-if="software.type === 'plugin'"
        variant="transparent"
        :href="`https://www.wordpress.org/plugins/${software.slug}`"
        target="_blank"
      >
        <i class="far fa-arrow-up-right-from-square mr-2" />Plugin bekijken
      </b-button>

      <b-button
        v-if="software.type === 'theme'"
        variant="transparent"
        :href="`https://www.wordpress.org/themes/${software.slug}`"
        target="_blank"
      >
        <i class="far fa-arrow-up-right-from-square mr-2" />Thema bekijken
      </b-button>
    </div>

    <b-card class="card-table" no-body>
      <b-table
        :items="vulnerabilities"
        :fields="fields"
        no-local-sorting
        class="rows-are-clickable"
        @row-clicked="
          $root.rowClickRedirect($event, 'software.show.vulnerability', {
            id: $event.software_id,
            vulnerabilityId: $event.id,
          })
        "
      >
        <template #cell(patched)="row">
          <template v-if="row.item.patched">
            <i class="far fa-circle-check text-success" />
          </template>

          <template v-else>
            <i class="far fa-circle-xmark text-danger" />
          </template>
        </template>
      </b-table>
    </b-card>
  </b-container>
</template>

<script>
  export default {
    data() {
      return {
        loading: {
          software: false,
        },

        fields: [
          {
            key: "title",
            label: "Titel",
          },
          {
            key: "patched",
            label: "Patched",
          },
          {
            key: "cvss_score",
            label: "CVSS",
          },
          {
            key: "vulnerability_published",
            label: "Aangemaakt op",
          },
          {
            key: "vulnerability_updated",
            label: "Bijgewerkt op",
          },
        ],

        errors: [],

        software: {},

        vulnerabilities: [],
        vulnerabilitiesMeta: {},
      };
    },

    methods: {
      get() {
        this.loading.software = true;

        this.$http
          .get(`software/${this.$route.params.id}`)
          .then(({ data }) => {
            this.software = data.data;
          })
          .finally(() => {
            this.loading.software = false;
          });
      },

      getVulnerabilities() {
        this.loading.vulnerabilities = true;

        let params = {
          softwareId: this.$route.params.id,
        };

        this.$http
          .get(`vulnerabilities`, { params: params })
          .then(({ data }) => {
            this.vulnerabilities = data.data;
            this.vulnerabilitiesMeta = data.meta;
          })
          .finally(() => {
            this.loading.vulnerabilities = false;
          });
      },
    },

    mounted() {
      this.get();
      this.getVulnerabilities();
    },
  };
</script>
