<template>
  <div>
    <div class="p-5">
      <b-form-textarea
          id="code"
          class=""
          v-model="site.playwright_code"
          placeholder="Plaats hier de code die uitgevoerd moet worden door Playwright..."
          rows="15"
          max-rows="60"
      ></b-form-textarea>

      <div class="d-flex flex-row-reverse">
        <b-button class="mt-4" @click="$emit('update')">
          <i class="far fa-save mr-2" />
          Opslaan
        </b-button>
      </div>

    </div>
    </div>

</template>

<script>
  export default {
    props: {
      site: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
  };
</script>