<template>
  <b-form-group
    :label-for="id"
    :invalid-feedback="error"
    :state="error ? false : null"
    :description="description"
    :class="formGroupClass"
  >
    <template v-if="prepend || append">
      <b-input-group :prepend="prepend" :append="append">
        <b-input
          :id="id"
          :placeholder="placeholder"
          :value="value"
          :type="type"
          :state="error ? false : null"
          :step="step"
          :min="min"
          :max="max"
          :disabled="disabled"
          :number="type === 'number'"
          @input="emitInput"
          @update="emitUpdate"
          @blur="emitBlur"
          @change="emitChange"
          @keyup.enter="emitEnterPress"
        />
      </b-input-group>
    </template>
    <template v-else>
      <b-input
        :id="id"
        :placeholder="placeholder"
        :value="value"
        :type="type"
        :size="size"
        :state="error ? false : null"
        :step="step"
        :min="min"
        :max="max"
        :disabled="disabled"
        :number="type === 'number'"
        @input="emitInput"
        @update="emitUpdate"
        @blur="emitBlur"
        @change="emitChange"
        @keyup.enter="emitEnterPress"
      />
    </template>

    <template v-slot:label v-if="label || fakeLabel">
      <template v-if="fakeLabel"> &nbsp; </template>

      {{ label }}
      <span v-b-tooltip.hover v-if="tooltip && !hideTooltip" :title="tooltip">
        <i class="info-form far fa-info-circle" />
      </span>
    </template>
    <slot></slot>

    <template v-slot:description v-if="description">
      <span class="input-description">
        {{ description }}
      </span>
    </template>
  </b-form-group>
</template>

<script>
  /**
   * This component uses two way data binding, this means you can
   * use the v-model attribute when using the component
   *
   * @example <Input v-model={model} label="{label}" etc..></Input>
   *
   * @author Michael Lanting <michael@nordique.nl>
   * @version 1.0.0
   */
  export default {
    props: {
      /**
       * The unique ID used for linking the labels to the input field
       */
      id: {
        type: String,
        default: null,
      },

      /**
       * The label to be shown
       */
      label: {
        type: String,
        default: null,
      },

      /**
       * The error to be shown when validation fails
       */
      error: {
        default: null,
      },

      /**
       * The value to track
       */
      value: {
        default: null,
      },

      /**
       * The placeholder to be shown
       */
      placeholder: {
        type: String,
        default: null,
      },

      /**
       * The description to be shown
       */
      description: {
        type: String,
        default: null,
      },

      /**
       * The steps on a number field
       */
      step: {
        default: null,
      },

      /**
       * The min for a number field
       */
      min: {
        default: null,
      },

      /**
       * The max for a number field
       */
      max: {
        default: null,
      },

      /**
       * The tooltip to be shown
       */
      tooltip: {
        type: String,
        default: null,
      },

      /**
       * String to prepend before the input
       */
      prepend: {
        type: String,
        default: null,
      },

      /**
       * String to append after the input
       */
      append: {
        type: String,
        default: null,
      },

      /**
       * Disabled state
       */
      disabled: {
        type: Boolean,
        default: false,
      },

      /**
       * Disabled state
       */
      hideTooltip: {
        type: Boolean,
        default: false,
      },

      /**
       * Fake label
       */
      fakeLabel: {
        type: Boolean,
        default: false,
      },

      /**
       * Disabled state
       */
      size: {
        type: String,
        default: null,
      },

      formGroupClass: {
        default: null,
      },

      /**
       * The type of input
       * @values text, number, email, password, search, url, tel, date, time, range, color
       */
      type: {
        type: String,
        default: "text",
        validator: function (value) {
          return (
            [
              "text",
              "number",
              "email",
              "password",
              "search",
              "url",
              "tel",
              "date",
              "time",
              "range",
              "color",
            ].indexOf(value) !== -1
          );
        },
      },
    },

    methods: {
      emitInput(value) {
        if (this.type !== "search" || value.length >= 3 || value.length === 0) {
          this.$emit("input", value);
        }
      },

      emitBlur(value) {
        if (this.type !== "search" || value.length >= 3 || value.length === 0) {
          this.$emit("blur", value);
        }
      },

      emitChange(value) {
        if (this.type !== "search" || value.length >= 3 || value.length === 0) {
          this.$emit("change", value);
        }
      },

      emitUpdate(value) {
        if (this.type !== "search" || value.length >= 3 || value.length === 0) {
          this.$emit("update", value);
        }
      },
    },
  };
</script>
