<template>
  <div>
    <b-table :fields="fields" :items="dataset"></b-table>
  </div>
</template>

<script>
  export default {
    props: {
      dataset: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },

    data() {
      return {
        fields: [
          { key: "name", label: "Naam" },
          { key: "slug", label: "Slug" },
          {
            key: "draft",
            label: "Concept",
            formatter: (value) => {
              return value || "-";
            },
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "published",
            label: "Gepubliceerd",
            formatter: (value) => {
              return value || "-";
            },
            thClass: "text-center",
            tdClass: "text-center",
          },
        ],
      };
    },
  };
</script>