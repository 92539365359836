<template>
  <b-pagination-nav
    use-router
    first-number
    last-number
    v-model="currentPageValue"
    :number-of-pages="numberOfPages"
    :link-gen="$root.linkGen"
    @change="emitChange"
    class="mt-4"
  >
    <template #prev-text>
      <i class="far fa-angle-left" />
    </template>
    <template #next-text>
      <i class="far fa-angle-right" />
    </template>
    <template #ellipsis-text>
      <i class="fal fa-ellipsis-h" />
    </template>
  </b-pagination-nav>
</template>

<script>
  export default {
    props: {
      currentPage: {
        type: Number,
        default: 1,
      },

      numberOfPages: {
        type: Number,
        default: 1,
      },
    },

    computed: {
      currentPageValue: {
        get() {
          return this.currentPage;
        },
        set(page) {
          this.$emit("page-changed", page);
        },
      },
    },

    methods: {
      emitChange(value) {
        this.$emit("change", value);
      },
    },
  };
</script>