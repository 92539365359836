var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{attrs:{"fluid":""}},[_c('h1',[_vm._v(" "+_vm._s(_vm.vulnerability.title)+" ")]),_c('div',{staticClass:"mb-4"},[_c('b-button',{attrs:{"variant":"transparent","to":{
        name: 'software.show',
        params: {
          id: _vm.$route.params.id,
        },
      }}},[_c('i',{staticClass:"far fa-angle-left mr-2"}),_vm._v("Terug ")])],1),_c('b-row',[_c('b-col',[_c('b-card',[_c('div',[_c('strong',[_vm._v("Beschrijving")]),_c('p',[_vm._v(_vm._s(_vm.vulnerability.description))])]),_c('div',{staticClass:"mt-3"},[_c('strong',[_vm._v("Getroffen versie(s)")]),_c('ul',_vm._l((_vm.vulnerability.affected_versions),function(version,index){return _c('li',{key:`reference-${index}`},[(version.from_version === '*')?[_vm._v(" < "+_vm._s(version.to_version)+" ")]:[_vm._v(" "+_vm._s(index)+" ")]],2)}),0)]),_c('div',{staticClass:"mt-3"},[_c('strong',[_vm._v("Gepatchte versie(s)")]),_c('ul',_vm._l((_vm.vulnerability.patched_versions),function(version,index){return _c('li',{key:`reference-${index}`},[_vm._v(" "+_vm._s(version)+" ")])}),0)]),_c('div',{staticClass:"mt-3"},[_c('strong',[_vm._v("Oplossing")]),_c('p',[_vm._v(_vm._s(_vm.vulnerability.remediation))])]),_c('div',{staticClass:"mt-3"},[_c('strong',[_vm._v("Referenties")]),_vm._l((_vm.vulnerability.references),function(reference,index){return _c('p',{key:`reference-${index}`},[_c('b-link',{attrs:{"href":reference,"target":"_blank"}},[_vm._v(_vm._s(reference))])],1)})],2)])],1),_c('b-col',{attrs:{"lg":"12"}},[_c('b-card',{staticClass:"card-table",attrs:{"no-body":""}},[_c('b-card-body',[_c('strong',[_vm._v(" Sites ("+_vm._s(_vm.vulnerability.site_vulnerability.length)+") ")])]),_c('div',{staticClass:"scrollable"},[_c('b-table-simple',{staticClass:"condensed"},[_c('b-tbody',_vm._l((_vm.vulnerability.site_vulnerability),function(instance,index){return _c('b-tr',{key:`installedPlugin-${index}`},[_c('b-td',[_c('router-link',{attrs:{"to":{
                      name: 'sites.show',
                      params: { id: instance.site_id },
                    }}},[_vm._v(" "+_vm._s(instance.site.name)+" ("+_vm._s(instance.site.plugins[0].version)+")")])],1),_c('b-td',[_c('i',{staticClass:"far",class:{
                      'fa-circle-check text-success': instance.fixed,
                      'fa-circle-xmark text-danger': !instance.fixed,
                    }})])],1)}),1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }