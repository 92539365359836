<template>
  <b-container fluid>
    <h1>
      {{ vulnerability.title }}
    </h1>

    <div class="mb-4">
      <b-button
        variant="transparent"
        :to="{
          name: 'software.show',
          params: {
            id: $route.params.id,
          },
        }"
      >
        <i class="far fa-angle-left mr-2" />Terug
      </b-button>
    </div>

    <b-row>
      <b-col>
        <b-card>
          <div>
            <strong>Beschrijving</strong>
            <p>{{ vulnerability.description }}</p>
          </div>

          <div class="mt-3">
            <strong>Getroffen versie(s)</strong>
            <ul>
              <li
                v-for="(version, index) of vulnerability.affected_versions"
                :key="`reference-${index}`"
              >
                <template v-if="version.from_version === '*'">
                  &lt; {{ version.to_version }}
                </template>
                <template v-else>
                  {{ index }}
                </template>
              </li>
            </ul>
          </div>

          <div class="mt-3">
            <strong>Gepatchte versie(s)</strong>
            <ul>
              <li
                v-for="(version, index) of vulnerability.patched_versions"
                :key="`reference-${index}`"
              >
                {{ version }}
              </li>
            </ul>
          </div>

          <div class="mt-3">
            <strong>Oplossing</strong>
            <p>{{ vulnerability.remediation }}</p>
          </div>

          <div class="mt-3">
            <strong>Referenties</strong>
            <p
              v-for="(reference, index) of vulnerability.references"
              :key="`reference-${index}`"
            >
              <b-link :href="reference" target="_blank">{{ reference }}</b-link>
            </p>
          </div>
        </b-card>
      </b-col>

      <b-col lg="12">
        <b-card class="card-table" no-body>
          <b-card-body>
            <strong>
              Sites ({{ vulnerability.site_vulnerability.length }})
            </strong>
          </b-card-body>

          <div class="scrollable">
            <b-table-simple class="condensed">
              <b-tbody>
                <b-tr
                  v-for="(instance, index) of vulnerability.site_vulnerability"
                  :key="`installedPlugin-${index}`"
                >
                  <b-td>
                    <router-link
                      :to="{
                        name: 'sites.show',
                        params: { id: instance.site_id },
                      }"
                    >
                      {{ instance.site.name }} ({{
                        instance.site.plugins[0].version
                      }})</router-link
                    >
                  </b-td>
                  <b-td>
                    <i
                      class="far"
                      :class="{
                        'fa-circle-check text-success': instance.fixed,
                        'fa-circle-xmark text-danger': !instance.fixed,
                      }"
                    />
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import semver from "semver";

  export default {
    data() {
      return {
        loading: {
          vulnerability: false,
        },

        errors: [],
        vulnerability: {
          software: {},
        },
      };
    },

    methods: {
      get() {
        this.loading.vulnerability = true;

        this.$http
          .get(`vulnerabilities/${this.$route.params.vulnerabilityId}`)
          .then(({ data }) => {
            this.vulnerability = data.data;
          })
          .finally(() => {
            this.loading.vulnerability = false;
          });
      },
    },

    mounted() {
      this.get();
    },
  };
</script>
