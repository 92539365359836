<template>
  <b-form-group
    :label-for="id"
    :invalid-feedback="error"
    :state="error ? false : null"
    :description="description"
  >
    <b-textarea
      :id="id"
      :placeholder="placeholder"
      :value="value"
      :state="error ? false : null"
      :rows="rows"
      :disabled="disabled"
      @input="emitInput"
      @update="emitUpdate"
      @blur="emitBlur"
      @change="emitChange"
    />

    <template v-slot:label v-if="label">
      {{ label }}
      <span v-b-tooltip.hover v-if="tooltip && !hideTooltip" :title="tooltip">
        <i class="info-form far fa-info-circle" />
      </span>
    </template>
    <slot></slot>
    <template v-slot:description v-if="description">
      <span class="input-description">
        {{ description }}
      </span>
    </template>
  </b-form-group>
</template>

<script>
  /**
   * This component uses two way data binding, this means you can
   * use the v-model attribute when using the component
   *
   * @example <TextArea v-model={model} label="{label}" etc..></TextArea>
   *
   * @author Michael Lanting <michael@nordique.nl>
   * @version 1.0.0
   */
  export default {
    props: {
      /**
       * The unique ID used for linking the labels to the input field
       */
      id: {
        type: String,
        default: null,
      },

      /**
       * The label to be shown
       */
      label: {
        type: String,
        default: null,
      },

      /**
       * The error to be shown when validation fails
       */
      error: {
        default: null,
      },

      /**
       * The value to track
       */
      value: {
        default: null,
      },

      /**
       * The placeholder to be shown
       */
      placeholder: {
        type: String,
        default: null,
      },

      /**
       * The description to be shown
       */
      description: {
        type: String,
        default: null,
      },

      /**
       * The rows
       */
      rows: {
        default: 4,
      },

      /**
       * The tooltip to be shown
       */
      tooltip: {
        type: String,
        default: null,
      },

      /**
       * Disabled state
       */
      disabled: {
        type: Boolean,
        default: false,
      },

      /**
       * Disabled state
       */
      hideTooltip: {
        type: Boolean,
        default: false,
      },
    },

    methods: {
      emitInput(value) {
        if (this.type !== "search" || value.length >= 3 || value.length === 0) {
          this.$emit("input", value);
        }
      },

      emitBlur(value) {
        if (this.type !== "search" || value.length >= 3 || value.length === 0) {
          this.$emit("blur", value);
        }
      },

      emitChange(value) {
        if (this.type !== "search" || value.length >= 3 || value.length === 0) {
          this.$emit("change", value);
        }
      },

      emitUpdate(value) {
        if (this.type !== "search" || value.length >= 3 || value.length === 0) {
          this.$emit("update", value);
        }
      },
    },
  };
</script>
