<template>
  <div>
    <b-nav class="main-navigation">
      <div class="navigation-group">
        <Logo />

        <b-nav-item
          v-for="(item, index) in menuItems"
          :key="index"
          :to="item.link"
          :active="$route.name === item.link.name"
          link-classes="d-flex align-items-center justify-content-between"
        >
          <span class="nav-item-icon">
            <i :class="`far fa-${item.icon}`" />
          </span>
          <span class="flex-grow-1 nav-item-text">
            {{ item.title }}
          </span>
        </b-nav-item>
      </div>

      <div class="navigation-group">
        <b-nav-item @click="signOut">
          <i class="far fa-sign-out" />
        </b-nav-item>
        <b-nav-item>
          <Avatar :user="auth" />
        </b-nav-item>
      </div>
    </b-nav>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import Avatar from "@/components/Avatar";
  import Logo from "@/components/Logo";

  export default {
    components: {
      Avatar,
      Logo,
    },

    data() {
      return {
        menuItems: [],
      };
    },

    computed: {
      ...mapGetters({
        auth: "auth/user",
      }),
    },

    methods: {
      ...mapActions({
        signOutAction: "auth/signOut",
      }),

      signOut() {
        this.signOutAction().then(() => {
          this.$router.replace({
            name: "auth.login",
          });
        });
      },

      getNavigationItems() {
        this.$router.options.routes.forEach((route) => {
          if (route.meta && route.meta.inNavigation) {
            this.menuItems.push({
              title: route.meta.title,
              icon: route.meta.icon,
              name: route.name,
              navigationOrder: route.meta.navigationOrder,
              link: { name: route.name },
            });
          }
        });

        this.menuItems = this.menuItems.sort((a, b) => {
          return a.navigationOrder - b.navigationOrder;
        });
      },
    },

    created() {
      this.getNavigationItems();
    },
  };
</script>