<template>
  <div>
    <b-table-simple class="table-first-column-bold">
      <b-tbody>
        <b-tr>
          <b-td>Host</b-td>
          <b-td>
            {{ dataset.host }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Naam</b-td>
          <b-td>
            {{ dataset.name }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Software</b-td>
          <b-td>
            {{ dataset.software }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Protocol</b-td>
          <b-td>
            {{ dataset.protocol }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Server IP</b-td>
          <b-td>
            {{ dataset.ip }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Encoding</b-td>
          <b-td>
            {{ dataset.encoding }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>PHP Versie</b-td>
          <b-td>
            {{ dataset.php }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Geheugen</b-td>
          <b-td>
            {{ dataset.php_memory }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Max Post Size</b-td>
          <b-td>
            {{ dataset.php_post_max_size }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Max Execution Time</b-td>
          <b-td>
            {{ dataset.php_max_execution_time }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Max Filesize</b-td>
          <b-td>
            {{ dataset.php_upload_max_filesize }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>MySQL Versie</b-td>
          <b-td>
            {{ dataset.mysql }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
  export default {
    props: {
      dataset: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
  };
</script>