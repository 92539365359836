<template>
  <div>
    <b-avatar
      :src="user.avatar ? `/media/avatars/${user.avatar}` : null"
      :text="initials"
      :size="size"
      rounded
      badge-variant="warning"
      :id="`avatar-tooltip-target-${uniqueKey}`"
    >
      <template v-if="user.role_id === 2" #badge>
        <i class="fas fa-star text-white" />
      </template>
    </b-avatar>

    <b-tooltip
      v-if="tooltip"
      :target="`avatar-tooltip-target-${uniqueKey}`"
      triggers="hover"
    >
      {{ user.formatted_name }}
    </b-tooltip>
  </div>
</template>

<script>
  export default {
    props: {
      user: {
        type: Object,
        default: () => {
          return {
            avatar: null,
            formatted_name: "",
          };
        },
      },

      size: {
        type: String,
        default: () => {
          return "md";
        },
      },

      tooltip: {
        type: Boolean,
        default: () => {
          return false;
        },
      },

      uniqueKey: {
        type: Number,
        default: () => {
          return 0;
        },
      },
    },

    computed: {
      initials() {
        let name = this.user.formatted_name.split(" ");

        if (name.length === 1) {
          name = name.at(0).charAt(0);
        } else {
          name = name.at(0).charAt(0) + name.at(-1).charAt(0);
        }

        return name;
      },
    },
  };
</script>